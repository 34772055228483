







import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Logout extends Vue {
  mounted () {
    this.$store.commit('identity/setUser', null)

    Services.CredentialManager.DeleteOAuthCredential()

    this.$router.push('/account/login')
  }
}
